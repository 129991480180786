var columns = [{
  title: '系列图片',
  dataIndex: 'imgurl',
  key: 'imgurl',
  width: '20%',
  scopedSlots: {
    customRender: 'imgurl'
  }
}, {
  title: '系列名称',
  dataIndex: 'name',
  key: 'name',
  width: '18%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '类目',
  dataIndex: 'parentcategory',
  key: 'parentcategory',
  width: '22%',
  scopedSlots: {
    customRender: 'parentcategory'
  }
}, {
  title: '品牌名称',
  dataIndex: 'brandmsg',
  key: 'brandmsg',
  width: '20%',
  scopedSlots: {
    customRender: 'brandmsg'
  }
}, {
  title: '商品',
  dataIndex: 'total',
  key: 'total',
  width: '15%',
  scopedSlots: {
    customRender: 'total'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };